import RegisterContainer from "../Register";
import withHomeHOC from "./withHomeHOC";

const HomeWithRegister = () => {
    return <HomeWithRegisterHOC />
}

const HomeWithRegisterHOC = withHomeHOC(RegisterContainer);


export default HomeWithRegister;
