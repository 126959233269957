import styled from "@emotion/styled";
import Sidebar from "./Sidebar";
import MainContent from "./MainContent";
import { useRecoilState, useRecoilValue } from "recoil";
import sidebar from "../../state/sidebar";
import { useEffect } from "react";
import Gateway from "../../common/Gateway";
import { user } from "../../state/user";

function Dashboard() {
    const _sidebar = useRecoilValue(sidebar);
    const [userDetails, setUserDetails] = useRecoilState(user);
    useEffect(() => {
        Gateway.getUserInfo(userDetails.getEmail()).then(res => {
            setUserDetails({
                ...userDetails,
                info: res.data
            });
        });
    }, []);

    return (
        <Wrapper expanded={_sidebar.expanded}>
            <Sidebar />
            <MainContent />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    > * {
        height: 100vh;
        &:nth-of-type(1n) {
            width: ${props => props.expanded ? '250px' : '0'};
        }
        &:nth-of-type(2n) {
            // width: 100%;
            flex: 1;
            max-width: ${props => props.expanded ? 'calc(100% - 250px)' : '100%'};
        }
    }
`;

export default Dashboard;
