import { useState } from "react";
import { toast } from "react-hot-toast";
import { useRecoilValue } from "recoil";
import Gateway from "../../../../common/Gateway";
import { user, userRole } from "../../../../state/user";
import columns from "./../../../../config/tables/licenses.json";
import ReactDOM from "react-dom";
import Util from "../../../../utils/Util";
import SupportRequestPopup from "../common/SupportRequestPopup";
import { USER_ROLES } from "../../../../common/constants";
import LoadedComponent from "../../../LoadedComponent";

const Licenses = () => {
    const [licenseId, setLicenseID] = useState("");
    const [popup, setShowPopup] = useState(false);
    const userDetails = useRecoilValue(user);
    const role = useRecoilValue(userRole);
    const loader = async (email, role) => {
        if (role === USER_ROLES.ADMIN) {
          const response = await Gateway.getLicenses();
          return response;
        } else {
          const response = await Gateway.getUserLicenses(email);
          return response;
        }
    }
    const getData = () => {
        try {
            const email = userDetails.getEmail();
            return loader(email,role);
        } catch(err) {
            toast.error("Failed to fetch licenses");
        }
    }
    return <>
        <LoadedComponent 
            loaded={!!role}
            title="Licenses"
            type="license"
            dataKey="licenses"
            columns={columns}
            handleAction={(row, data) => {
                setLicenseID(data.license_id)
                setShowPopup(true);
            }}
            handleEdit={(data) => {
                const editableData = Util.getEditableData(data, columns);
                return Gateway.updateLicense(editableData);
            }}
            getData={getData}
        />
        {
            popup && ReactDOM.createPortal(<SupportRequestPopup
                license_id={licenseId} 
                onClose={() => setShowPopup(false)} />, Util.getModalContainer())
        }
    </>
}

export default Licenses;
