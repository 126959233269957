import { toast } from "react-hot-toast";
import { CONSTANTS, USER_ROLES } from "../common/constants";
import AdminUtil from "./Admin.util";
import DeveloperUtil from "./Developer.util";
import Cookies from "js-cookie";

class Util {
    static getSidebarItems(role) {
        if (role === USER_ROLES.ADMIN) {
            return AdminUtil.getSidebarItems();
        }
        return DeveloperUtil.getSidebarItems();
    }

    static getModalContainer() {
        const element = document.getElementById("popup-mount");
        return element;
    }

    static getHeaderPortal() {
        const element = document.getElementById("header-mount-hook");
        return element;
    }

    static downloadFile(base64String, fileName) {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: "application/octet-stream", name: "fileName"});
        const blobUrl = URL.createObjectURL(blob);
        // window.location = blobUrl;
        var a = document.createElement("a");
        a.style = "display: none";
        a.href = blobUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
    }

    static validateWithCaptcha() {
        return new Promise((resolve => {
            if (window.grecaptcha) {
                // const loadingToast = toast.loading("Contacting support");
                const response = window.grecaptcha.execute('6LcvqxoqAAAAANbkeROsxN8k6EpybwMBNFVtrLUc', { 
                    action: 'contact' 
                });
                resolve(response);
            }
        }))
    }

    static getEditableData(data, allFields) {
        let editableData = {};
        // reset to default values
        allFields.forEach((f) => {
            if (f.editable || f.required) {
                let fieldValue = data[f.field];
                if (f?.metadata?.displayType === "number") {
                    const parsedValue = parseInt(fieldValue);
                    fieldValue = parsedValue;
                    // if (f?.metadata?.defaultValue !== undefined) {
                    //     const parsedValue = parseInt(fieldValue);
                    //     if (parsedValue < 0) {
                    //         fieldValue = f?.metadata?.defaultValue;
                    //     }
                    // }
                }
                editableData[f.field] = fieldValue;
            }
        });
        return editableData;
    }

    static toastSuccess(msg) {
        toast.dismiss();
        toast.success(msg);
    }

    static capitalize(str) {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
        return str;
    }

    static clearLogin() {
        Cookies.remove(CONSTANTS.USER_EMAIL);
        Cookies.remove(CONSTANTS.USER_API_KEY);
    }
}

export default Util;
