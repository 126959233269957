import { toast } from "react-hot-toast";
import { useRecoilValue } from "recoil";
import { USER_ROLES } from "../../../../common/constants";
import Gateway from "../../../../common/Gateway";
import { user, userRole } from "../../../../state/user";
import columns from "./../../../../config/tables/nodes.json";
import LoadedComponent from "../../../LoadedComponent";
import Util from "../../../../utils/Util";

const Nodes = () => {
    const userDetails = useRecoilValue(user);
    const role = useRecoilValue(userRole);
    const loader = async (email, role) => {
        if (role === USER_ROLES.ADMIN) {
          const response = await Gateway.getNodes();
          return response;
        } else {
          const response = await Gateway.getUserNodes(email);
          return response;
        }
    }
    const getData = () => {
        try {
            const email = userDetails.getEmail();
            return loader(email, role);
        } catch (error) {
            toast.error("Failed to fetch nodes");
        }
    }
    return <LoadedComponent
        loaded={!!role}
        title="Nodes"
        type="node"
        dataKey="nodes"
        columns={columns}
        handleEdit={(data) => {
            const editableData = Util.getEditableData(data, columns);
            return Gateway.updateNode(editableData);
        }}
        getData={getData}
    />
}

export default Nodes;
