import { toast } from "react-hot-toast";
import { useRecoilValue } from "recoil";
import {USER_ROLES } from "../../../../common/constants";
import Gateway from "../../../../common/Gateway";
import { user, userRole } from "../../../../state/user";
import columns from "./../../../../config/tables/app-tokens.json";
import LoadedComponent from "../../../LoadedComponent";
import Util from "../../../../utils/Util";

const AppTokens = () => {
    const role = useRecoilValue(userRole);
    const userDetails = useRecoilValue(user);
    const loader = async (email, role) => {
        if (role === USER_ROLES.ADMIN) {
          const response = await Gateway.getAppTokens();
          return response;
        } else {
          const response = await Gateway.getUserAppTokens(email);
          return response;
        }
    }
    const getData = () => {
        try {
            const email = userDetails.getEmail();
            return loader(email,role);
        } catch(err) {
            toast.error("Failed to fetch app tokens");
        }
    }
    return <LoadedComponent
        loaded={!!role}
        title="App Tokens"
        type="app-tokens"
        dataKey="tokens"
        columns={columns}
        handleEdit={(data) => {
            const editableData = Util.getEditableData(data, columns);
            return Gateway.updateAppToken(editableData);
        }}
        getData={getData}
    />;
}

export default AppTokens;
