import { Box, IconButton, List, ListItem, Menu, MenuItem } from "@mui/material";
import pages from "../../config/nav.json";
import RemoveCircleIcon from "@mui/icons-material/ClearRounded";

const SidebarNav = (props) => {
    return (
        <Box sx={{ 
            position: "fixed", 
            top: 0, 
            right: 0, 
            width: "100vw",
            zIndex: "2",
            height: "100vh",
            color: "#fff",
            fontSize: "16px",
            display: "flex",
            flexDirection: "column-reverse"
        }}>
            <Box sx={{
                    flexGrow: 1,
                    opacity: "0.6",
                    backgroundColor: "#000"
                }}
                onClick={props.onClose}
            ></Box>
            <List sx={{
                width: "100vw",
                height: "fit-content",
                backgroundColor: "#673ab7",
                opacity: "0.9"
            }}>
            {pages.map((page, idx) => (
                <ListItem key={idx} onClick={() => props.handleNavigate(page)}
                    sx={{
                        marginTop: "12px",
                        gap: "24px",
                        paddingLeft: "48px",
                        cursor: "pointer",
                        fontSize: "28px"
                    }}
                >
                    <i className={ page.icon }></i>
                    <span style={{
                        fontSize: "16px"
                    }}>{ page.label }</span>
                </ListItem>
              ))}
              <ListItem sx={{
                textAlign: "center",
                justifyContent: "center",
                marginTop: "12px"
              }}>
                <IconButton sx={{
                    backgroundColor: "#fff"
                }} onClick={props.onClose}>
                    <RemoveCircleIcon />
                </IconButton>
              </ListItem>
            </List>
          </Box>
    );
}

export default SidebarNav;
