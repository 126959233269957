export const EMAIL_PATTERN =
    new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$");

export const NAME_PATTERN = new RegExp("^[a-zA-Z ]+$");

export const CONSTANTS = {
    USER_API_KEY: "USER_API_KEY",
    USER_EMAIL: "USER_EMAIL",
    USER_ROLE: "USER_ROLE"
};

export const USER_ROLES = {
    DEVELOPER: "DEVELOPER",
    ADMIN: "ADMIN"
};

export const DATATABLE = {
    COL_TYPE_ACTION: "$"
};

export const ERROR_MSGS = {
    UPDATE_API_ERROR: "Error updating {ENTITY}. Please retry after sometime",
    MAILED_SUPPORT: "Email sending failed. Please try again later or email on gatekeeper@ljnath.com.",
    ACCOUNT_DEACTIVATED: "Account deactivated. New users, please check your email for further instructions. For existing users, please contact support.",
    LOGIN_FAILED: "Login failed. Incorrect email or password entered.",
    SERVER_ERROR: "Sorry, a server error occurred. Please try again later."
};

export const SUCCESS_MSGS = {
    REGISTERED_MSG: "Registration successful and activation is pending. Please check your email for more details",
    MAILED_SUPPORT: "Support request sent via email. We'll get back to you soon. ",
    LOGIN_SUCCESS: "Login successful. Welcome back!"
};

export const DEFAULT_TIMESTAMP_FORMAT = "LLLL";

export const D_CONSTANTS = {
    FORMAT: "format"
};