import Login from "./Login";
import { Navigate, useNavigate } from "react-router-dom";
import Gateway from "../../common/Gateway";
import { user } from "../../state/user";
import { useRecoilState } from "recoil";
import styled from "@emotion/styled";

const LoginContainer = () => {
    const [userDetails, setUserDetails] = useRecoilState(user);
    const navigate = useNavigate();
    return (
        <Wrapper>
            {
                userDetails.isLoggedIn() 
                    ? <Navigate to="/" />
                    : <Login onLoginSuccess={(email, apiData) => {
                        if (email && apiData.api_key) {
                            Gateway.setUserDetails(email, apiData.api_key);
                        }
                        navigate("/");
                    }} />
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
`;

export default LoginContainer;
