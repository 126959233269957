import axios from "./axios";
import REST_CONSTANTS from "./api.constants";
import { CONSTANTS } from "./constants";
import Util from "../utils/Util";
import Cookies from "js-cookie";

class Gateway {

    static getConfig() {
        const USER_API_KEY = this.getUserApiKey();
        const config = {
            headers: {
                // "x-api-key":process?.env?.REACT_APP_API_KEY
                "x-api-key": USER_API_KEY || atob("MjExZWUwZGRhOGM2NDdiMDQ1N2YxYTlkZGUyZmZjZTNiNzQ5MjJkNA==")
            }
        }
        return config;
    }

    static setUserDetails(email, apiKey) {
        Cookies.set(CONSTANTS.USER_EMAIL, email, {
            expires: 1
        });
        this.setUserApiKey(apiKey);
    }

    static getUserApiKey() {
        return Cookies.get(CONSTANTS.USER_API_KEY);
    }

    static setUserApiKey(value) {
        Cookies.set(CONSTANTS.USER_API_KEY, value, {
            expires: 1
        });
    }
    
    static clearLogin() {
        Util.clearLogin();
    }


    static getNodes() {
        return axios.get(REST_CONSTANTS.GET_NODES, this.getConfig());
    }

    static getUserNodes(email) {
        const finalUrl = REST_CONSTANTS.GET_USER_NODES.replace("{EMAIL}", email);
        return axios.get(finalUrl, this.getConfig());
    }

    static updateNode(node) {
        // const body = {
        //     client_id: client.client_id,
        //     description: client.description,
        //     is_active: client.is_active
        // }
        return axios.patch(REST_CONSTANTS.UPDATE_NODE, node, this.getConfig());
    }

    static getApps() {
        return axios.get(REST_CONSTANTS.GET_APPS, this.getConfig());
    }

    static getUserApps(email) {
        const finalUrl = REST_CONSTANTS.GET_USER_APPS.replace("{EMAIL}", email);
        return axios.get(finalUrl, this.getConfig());
    }

    static updateApp(app) {
        // const body = {
        //     "application_id": app.application_id,
        //     "application_name": app.application_name,
        //     "is_active": app.is_active,
        //     "description": app.description,
        //     "download_url": app.download_url,
        //     "latest_version": app.latest_version,
        //     "product_url": app.product_url
        // };
        return axios.patch(REST_CONSTANTS.ADD_APP, app, this.getConfig());
    }

    /**
     * Add an app
     * @payload
     * "application_id": app.application_id,
     * "application_name": app.application_name,
     * "is_active": app.is_active,
     * "description": app.description,
     * "download_url": app.download_url,
     * "latest_version": app.latest_version,
     * "product_url": app.product_url
     * @returns 
     */
    static addApp(payload) {
        return axios.post(REST_CONSTANTS.ADD_APP, payload, this.getConfig());
    }

    static getAppTokens() {
        return axios.get(REST_CONSTANTS.GET_APP_TOKENS, this.getConfig());
    }

    static getUserAppTokens(email) {
        const finalUrl = REST_CONSTANTS.GET_USER_APP_TOKENS.replace("{EMAIL}", email);
        return axios.get(finalUrl, this.getConfig());
    }

    static updateAppToken(payload) {
        // const body = {
        //     "token_id": payload.application_id,
        //     "is_active": payload.is_active
        // };
        return axios.patch(REST_CONSTANTS.UPDATE_APP_TOKEN, payload, this.getConfig());
    }

    static createAppToken(payload) {
        // const payload = {
        //     application_id: "IIII898-XXXXX-XXXX"
        // }
        return axios.post(REST_CONSTANTS.CREATE_APP_TOKEN, payload, this.getConfig());
    }

    static getUsers() {
        return axios.get(REST_CONSTANTS.GET_USERS, this.getConfig());
    }

    static updateUserProfile(user) {
        return axios.patch(REST_CONSTANTS.UPDATE_USER, user, this.getConfig());
    }

    static updateUser(user) {
        // const body = {
        //     "email": user.email,
        //     "is_active": user.is_active,
        //     "name": user.name
        // };
        return axios.patch(REST_CONSTANTS.UPDATE_USER, user, this.getConfig());
    }

    static register(user, recaptcha_token) {
        const payload = {
            email: user.email,
            password: user.password,
            name: user.name,
            recaptcha_token
        };
        return axios.post(REST_CONSTANTS.PROXY_REGISTER, payload, this.getConfig());
    }

    static getSupportRequest() {
        return axios.get(REST_CONSTANTS.GET_SUPPORT_REQUESTS, this.getConfig());
    }

    static createSupportRequest(command, license_id) {
        const payload = {
            command,
            license_id
        };
        return axios.post(REST_CONSTANTS.CREATE_SUPPORT_REQUESTS, payload, this.getConfig());
    }

    static getUserSupportRequest(email) {
        const finalUrl = REST_CONSTANTS.GET_USER_SUPPORT_REQUEST.replace("{EMAIL}", email);
        return axios.get(finalUrl, this.getConfig());
    }

    static updateSupportRequest(request) {
        // const body = {
        //     "request_id": request.request_id,
        //     "command": request.command,
        //     "state": request.state
        // };
        return axios.patch(REST_CONSTANTS.UPDATE_SUPPORT_REQUEST, request, this.getConfig());
    }

    static getSupportResponse() {
        return axios.get(REST_CONSTANTS.GET_SUPPORT_RESPONSE, this.getConfig());
    }

    static getUserSupportResponse(email) {
        const finalUrl = REST_CONSTANTS.GET_USER_SUPPORT_RESPONSE.replace("{EMAIL}", email);
        return axios.get(finalUrl, this.getConfig());
    }

    static getLicenses() {
        return axios.get(REST_CONSTANTS.GET_LICENSES, this.getConfig());
    }

    static getUserLicenses(email) {
        const finalUrl = REST_CONSTANTS.GET_USER_LICENSES.replace("{EMAIL}", email);
        return axios.get(finalUrl, this.getConfig());
    }

    static updateLicense(license) {
        // const body = {
        //     "license_id": license.license_id,
        //     "application_version": license.application_version,
        //     "exit_timeout": license.exit_timeout,
        //     "expiry_message": license.expiry_message,
        //     "state": license.state,
        //     "type": license.type,
        //     "validity": license.validity
        // };
        return axios.patch(REST_CONSTANTS.UPDATE_LICENSE, license, this.getConfig());
    }

    /**
     * 
     * @param {*} email 
     * @returns 
     * api_key: "a72994bf0b801216e1e5fcbba919bc07cfd445ae"
     * created_at: "2023-03-22 18:13:25 +0000"
     * created_by: "lakhya.sci@gmail.com"
     * email: "manoj.adams@gmail.com"
     * is_active: true
     * name: "Manoj Baruah"
     * role: "DEVELOPER"
     * updated_at: "2023-03-22 18:13:25 +0000"
     * updated_by: "lakhya.sci@gmail.com"
     */
    static getUserInfo(email) {
        const finalUrl = REST_CONSTANTS.GET_USER_INFO.replace("{EMAIL}", email);
        return axios.get(finalUrl, this.getConfig());
    }

    static getUserStats() {
        return axios.get(REST_CONSTANTS.GET_USER_STATS, this.getConfig());
    }

    static login(params, recaptcha_token) {
        const payload = {
            ...params,
            recaptcha_token,
        };
        return axios.post(REST_CONSTANTS.PROXY_LOGIN, payload, this.getConfig());
    }

    static changePassword(params) {
        const payload = {
                "email": params.email,
                "password": params.password,
                "new_password": params.new_password
        }
        return axios.post(REST_CONSTANTS.CHANGE_USER_PASSWORD, payload, this.getConfig());
    }

    static getFile(id) {
        const finalUrl = REST_CONSTANTS.GET_FILE.replace("{ID}", id);
        return axios.get(finalUrl, this.getConfig());
    }

    static contactSupport(body, recaptcha_token) {
        const formData = {
            ...body,
            recaptcha_token
        }
        return axios.post(REST_CONSTANTS.PROXY_SUPPORT, formData, this.getConfig());
    }
}

export default Gateway;
