import styled from "@emotion/styled";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import Gateway from "../../../common/Gateway";
import { user } from "../../../state/user";
import MenuIcon from '@mui/icons-material/Menu';
import sidebar from "../../../state/sidebar";

const Toolbar = (props) => {
    const [userDetails, setUserDetails] = useRecoilState(user);
    const [sidebarDetails, setSidebarDetails] = useRecoilState(sidebar);

    useEffect(() => {
        Gateway.getUserInfo(userDetails.getEmail()).then(res => {
            setUserDetails({
                ...userDetails,
                info: res.data
            });
        });
    }, []);
    return (
        <Wrapper>
            <div className="menu">
                <MenuIcon style={{
                    cursor: "pointer"
                }} onClick={() => {
                    setSidebarDetails({
                        expanded: !sidebarDetails.expanded
                    });
                }}/>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    color: var(--d-primary-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .role {
        margin-left: 32px;
        margin-right: auto;
        font-size: 12px;
    }
    .profile {
        display: flex;
        width: 220px;
        flex-direction: row;
        justify-content: space-between;
        .user {
            display: flex;
            align-items: center;
            .name {
                margin-right: 8px;
                font-size: 12px;
                white-space: nowrap;
                margin-right: 8px;
                font-size: 12px;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 140px;
            }
        }
    }
`;

export default Toolbar;
