import styled from "@emotion/styled";
import { Component } from "react";
import { createPortal } from "react-dom";
import { toast } from "react-hot-toast";
import { ERROR_MSGS } from "../../../../common/constants";
import Util from "../../../../utils/Util";
import EditFormPopup from "../../../EditFormPopup/EditFormPopup";
import DataTableComponent from "../DataTableComponent";

const MainContentHOC = (TableComponent) => {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                rows: [],
                popup: false,
                popupData: {}
            }
        }
        componentDidMount() {
            this.getData();
        }
        getData() {
            this.props.getData().then(res => {
                this.setState({
                    rows: res.data[this.props.dataKey]
                })
            })
        }
        render() {
            return (
                <Wrapper>
                    <TableComponent 
                        title={this.props.title}
                        rows={this.state.rows}
                        columns={this.props.columns}
                        handleAction={(row, col) => {
                            if (col.metadata?.type === "edit") {
                                this.setState({
                                    popup: true,
                                    popupData: {row, col: this.props.columns}
                                })
                            } else {
                                this.props.handleAction(col.metadata?.type, row);
                            }
                        }}
                        onLoaded={this.props.handleLoaded}
                    />
                    { this.showEditPopup() }
                </Wrapper>
        )}
        closePopup() {
            this.setState({
                popup: false,
                popupData: {}
            })
        }
        showEditPopup() {
            return this.state.popup && createPortal(<EditFormPopup
                data={this.state.popupData}
                title={"Edit " + this.props.title}
                onClose={() => this.closePopup()} 
                onSubmit={(data) => {
                    this.props.handleEdit(data).then(res => {
                        this.getData();
                        Util.toastSuccess(Util.capitalize(this.props.type) + " updated");
                        this.closePopup();
                    }).catch(err => {
                        const errorMsg = err?.response?.data?.message;
                        toast.error(errorMsg || ERROR_MSGS.UPDATE_API_ERROR.replace("{ENTITY}", this.props.type));
                    });
                }}
            />, Util.getModalContainer())
        }
    }
}

const Wrapper = styled.div`
    padding: 16px;
`;

export default MainContentHOC(DataTableComponent);
