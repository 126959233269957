import React, {useState } from "react";
import { Form, FormWrapper } from "../../common/styled";
import { Button, TextField, ThemeProvider } from "@mui/material";
import { GATEKEEPER_THEME } from "../../common/theme";
import styled from "@emotion/styled";
import Gateway from "../../common/Gateway";
import { useScript } from "../../common/hooks";
import { toast } from "react-hot-toast";
import { EMAIL_PATTERN, ERROR_MSGS, NAME_PATTERN, SUCCESS_MSGS } from "../../common/constants";
import { useNavigate } from "react-router-dom";
import Util from "../../utils/Util";

const ContactUs = () => {
    const [formValues, setFormValues] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    });
    const [errors, setErrors] = useState({
        name: false,
        nameErrorMsg: "",
        email: false,
        emailErrorMsg: "",
        subject: false,
        subjectErrorMsg: "",
        message: false,
        messageErrorMsg: ""
    });
    const navigate = useNavigate();
    const handleChange = (e) => {
        setFormValues({...formValues, [e.target.name]: e.target.value})
    }
    const handleValidation = (e) => {
        let emailValid = errors.email;
        let emailErrorMsg = "";
        let nameValid = errors.password;
        let nameErrorMsg = "";
        let subjectValid = errors.subject;
        let subjectErrorMsg = "";
        let messageValid = errors.message;
        let messageErrorMsg = "";
        if (formValues.email && EMAIL_PATTERN.test(formValues.email)) {
            emailValid = true;
        } else {
            emailValid = false;
            emailErrorMsg = formValues.email ? "Please enter valid email address." : "Email is mandatory";
        }
        if (formValues.name && NAME_PATTERN.test(formValues.name)) {
            nameValid = true;
        } else {
            nameValid = false;
            nameErrorMsg = formValues.name ? "Please enter valid Name" : "Name is mandatory";
        }
        if (formValues.subject) {
            subjectValid = true;
        } else {
            subjectValid = false;
            subjectErrorMsg = formValues.subject ? "Please enter valid Subject": "Subject is mandatory";
        }
        if (formValues.message) {
            messageValid = true;
        } else {
            messageValid = false;
            messageErrorMsg = "Message is mandatory";
        }
        setErrors({
            email: !emailValid,
            name: !nameValid,
            subject: !subjectValid,
            message: !messageValid,
            emailErrorMsg,
            nameErrorMsg,
            subjectErrorMsg,
            messageErrorMsg
        });
        return emailValid && nameValid && subjectValid && messageValid;
    }
    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const isValid = handleValidation();
        if (!isValid) return;
        if (window.grecaptcha) {
            const loadingToast = toast.loading("Contacting support");
            Util.validateWithCaptcha()
            .then(function (token) {
                return Gateway.contactSupport(formValues, token);
            })
            .then(() => {
                toast.remove(loadingToast);
                setFormValues({
                    name: "",
                    email: "",
                    message: "",
                    subject: "",
                });
                Util.toastSuccess(SUCCESS_MSGS.MAILED_SUPPORT);
                setTimeout(() => {
                    navigate("/");
                }, 500);
            })
            .catch(e => {
                toast.remove(loadingToast);
                console.error(e);
                toast.error(ERROR_MSGS.MAILED_SUPPORT);
            });
        }
    }
    useScript("https://www.google.com/recaptcha/api.js?render=6LcvqxoqAAAAANbkeROsxN8k6EpybwMBNFVtrLUc");
    return (
        <Wrapper>
            <FormWrapper>
                <ThemeProvider theme={GATEKEEPER_THEME}>
                    <Form id="contact_form" action="contact.php" method="POST">
                        <h2>Support</h2>
                        {/* <label for="name">Name:</label> */}
                        <TextField type="text" label="Name" id="name" name="name" required 
                            size="small"
                            error={errors.name}
                            helperText={errors.name ? errors.nameErrorMsg : ""}
                            value={formValues.name}
                            onChange={handleChange}
                        />
                        <TextField type="email" label="Email" id="email" name="email" 
                            error={errors.email}
                            helperText={errors.email ? errors.emailErrorMsg : ""}
                            value={formValues.email}
                            size="small"
                            onChange={handleChange}
                            required 
                        />
                        <TextField id="subject" label="Subject" name="subject" 
                            error={errors.subject}
                            helperText={errors.subject ? errors.subjectErrorMsg : ""}
                            value={formValues.subject}
                            inputProps={{
                                maxLength: 200
                            }}
                            size="small"
                            onChange={handleChange}
                            required 
                        />
                        <TextField 
                            id="message" 
                            error={errors.message}
                            helperText={errors.message ? errors.messageErrorMsg : ""}
                            label="Message" 
                            name="message" 
                            multiline
                            rows="5" 
                            required 
                            hei="true"
                            inputProps={{
                                maxLength: 2000
                            }}
                            value={formValues.message}
                            onChange={handleChange} />
                        <Button variant="contained" type="submit" 
                            onClick={handleSubmit}
                            >Submit</Button>
                    </Form>
                </ThemeProvider>
            </FormWrapper>
        </Wrapper>
    )
}

const TextArea = styled.textarea`
    height: 80px;
    border: 10px;
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 8px;
    font-family: auto;
    font-size: 16px;
    font-family: Poppins-Regular;
    &:focus-visible {
        outline-color: #000;
    }
`;

const Wrapper = styled.div`
    min-width: 360px;
    @media (max-width: 500px) {
        min-width: 280px;
    }
`;

export default ContactUs;
