import Gateway from "../../common/Gateway";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { ThemeProvider } from "@emotion/react";
import { FORM_THEME } from "../../common/theme";
import MuiForms from "mui-forms";
import { useRecoilState } from "recoil";
import { user } from "../../state/user";
import SchemaUtil from "../../utils/SchemaUtil";
import usersettings from "../../config/sidebar/user-settings.json";
import ToggleMenu from "../ToggleMenu/ToggleMenu";
import styled from "@emotion/styled";
import Util from "../../utils/Util";
import { Button } from "@mui/material";
import ChangePassword from "./ChangePassword";

const UserProfileSettings = () => {
    const [schema, setSchema] = useState();
    const [userInfo, setUserDetails] = useRecoilState(user);
    const [userData, setUserData] = useState({
        name: "",
        api_key: "",
        email: ""
    });
    const [changePassword, setChangePassword] = useState(false);
    useEffect(() => {
        if (userData.api_key) {
            const schema = SchemaUtil.toMetaformSchema({
                row: userData,
                col: usersettings
            });
            setSchema(schema);
        }
    }, [userData]);
    useEffect(() => {
        setUserData({
            name: userInfo.info.name,
            email: userInfo.info.email,
            api_key: userInfo.info.api_key
        })
    }, [userInfo]);

    const updateUserInfo = () => {
        Gateway.getUserInfo(userInfo.getEmail()).then(res => {
            setUserDetails({
                ...userInfo,
                info: res.data
            });
        });
    }
    return (
        <Wrapper>
            <ToggleMenu />
            <h1>Edit Profile</h1>
            {
                changePassword && <ChangePassword email={userInfo?.info?.email} onSuccess={() => {
                    setChangePassword(false);
                }}/>
            }
            {
                !changePassword && schema && 
                <ThemeProvider theme={FORM_THEME}>
                    <MuiForms 
                        config={{
                            variant: "standard"
                        }}
                        schema={schema} 
                        buttons={{
                            submit: <Button>Change password</Button>
                        }}
                        onSubmit={(data) => {
                            setChangePassword(true);
                            // const userData = {
                            //     name: data.name,
                            //     email: data.email,
                            //     is_active: userInfo.info.is_active
                            // };
                            // Gateway.updateUserProfile(userData).then(res => {
                            //     updateUserInfo();
                            //     Util.toastSuccess("Updated profile")
                            // }).catch(error => {
                            //     toast.error("Error updating profile");
                            // })
                        }}
                    />
                </ThemeProvider>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    padding: 16px;
`;

export default UserProfileSettings;
