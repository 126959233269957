import styled from "@emotion/styled";
import Popup from "../../../Popup";
import { Button } from "@mui/material";
import "./token-modal.css";

function TokenModal(props) {
    return (
        <Popup className="token-modal" onClose={props.onClose}>
            <FormHeader>{ props.title }</FormHeader>
            <Content>Are you sure to create a new token for <b>{props.name}</b>?</Content>
            <Footer>
                <Button className="action-btn" variant="contained" onClick={props.onSubmit}>Yes</Button>
                <Button variant="text" onClick={props.onClose}>No</Button>
            </Footer>
        </Popup>
    )
}

const FormHeader = styled.h2`
    position: absolute;
    top: 0;
    left: 36px;
`;

const Content = styled.p``;

// const Footer = styled.div``;

const Footer = styled.div`
    text-align: center;
    margin-top: 32px;
    .cancel-btn {
        margin-right: 32px;
    }
`;

export default TokenModal;
