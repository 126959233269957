import axios from "axios";
import config from "./../config";
import Util from "../utils/Util";

// 401 interceptor
axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 401) {
        const basepath = config.basepath;
        Util.clearLogin();
        document.location = basepath + "/login";
    } else {
        return error;
    }
});

export default axios;