import { InputAdornment, TextField, ThemeProvider } from "@mui/material";
import { SEARCHBAR_THEME } from "../../../../common/theme";
import SearchIcon from '@mui/icons-material/Search';

const Searchbar = (props) => {
    return (
        <ThemeProvider theme={SEARCHBAR_THEME}>
            <TextField 
                size="small"
                placeholder="Search"
                onChange={(e) => {
                    props.onSearch(e.target.value);
                }}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
            />
        </ThemeProvider>
    )
};

export default Searchbar;
