import './App.css';
import Dashboard from './components/Dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import MyApps from './components/Dashboard/MainContent/MyApps';
import AppTokens from './components/Dashboard/MainContent/AppTokens';
import Nodes from './components/Dashboard/MainContent/Nodes';
import Licenses from './components/Dashboard/MainContent/Licenses';
import SupportRequests from './components/Dashboard/MainContent/SupportRequests';
import SupportResponse from './components/Dashboard/MainContent/SupportResponse';
import PrivateRoute from './components/PrivateRoute';
import Users from './components/Dashboard/MainContent/Users';
import DashHome from './components/Dashboard/MainContent/DashHome/DashHome';
import HomeWithRegister from './components/Home/HomeWithRegister';
import HomeWithSupport from './components/Home/HomeWithSupport';
import HomeWithLogin from './components/Home/HomeWithLogin';
import UserProfileSettings from './components/Settings/UserProfileSettings';

function App() {
  return (
    <>
    <BrowserRouter basename="webapp">
      <Routes>
        <Route path="/login" Component={HomeWithLogin} />
        <Route path="/register" Component={HomeWithRegister} />
        <Route path="/support" Component={HomeWithSupport} />
        <Route path="/" element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }>
          <Route path="/" Component={DashHome} />
          <Route path="users" Component={Users} />
          <Route path="licenses" Component={Licenses} />
          <Route path="apps" Component={MyApps} />
          <Route path="app-tokens" Component={AppTokens} />
          <Route path="nodes" Component={Nodes} />
          <Route path="support-requests" Component={SupportRequests} />
          <Route path="support-response" Component={SupportResponse} />
          <Route path="edit-profile" Component={UserProfileSettings} />
          <Route path="*" Component={DashHome} />
        </Route>
      </Routes>
      <div id="popup-mount"></div>
    </BrowserRouter>
    </>
  );
}

export default App;
