import Gateway from "../../../../common/Gateway";
import columns from "./../../../../config/tables/users.json";
import LoadedComponent from "../../../LoadedComponent";
import Util from "../../../../utils/Util";

const Users = () => {
    const getData = () => {
        return Gateway.getUsers();
    }
    return <LoadedComponent
        loaded={true}
        title="Users"
        type="users"
        dataKey="users"
        columns={columns}
        handleEdit={(data) => {
            const editableData = Util.getEditableData(data, columns);
            return Gateway.updateUser(editableData);
        }}
        handleAction={(data) => {
            const editableData = Util.getEditableData(data, columns);
            return Gateway.updateUser(editableData);
        }}
        getData={getData}
    />;
}

export default Users;
