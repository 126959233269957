import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CancelRounded";
import "./popup.css";

const Popup = (props) => {
    return (
        <div className={"dialog " + props?.className ?? ""}>
            <div
                className="modal_dialog line-up-animation-class"
            >
                        <CloseRoundedIcon
                            className="close_btn"
                            onClick={() => {
                                props.onClose();
                            }}
                        />
                <div className="modal_content">
                    <div className="modal_header">
                        {props.modalHeader}
                    </div>
                    <div className="modal_body">
                        {props.children}
                    </div>
                    <div className="modal_footer">
                        {props.modalFooter}
                    </div>
                </div>
            </div>
    </div>
    )
}

export default Popup;