import { ThemeProvider } from "@mui/material";
import { FORM_THEME } from "../../common/theme";
import Popup from "../Popup";
import MuiForms from "mui-forms";
import styled from "@emotion/styled";

function AddFormPopup(props) {
    return (
        <Popup onClose={props.onClose}>
            <FormHeader>{ props.title }</FormHeader>
            {
                props.schema && 
                <ThemeProvider theme={FORM_THEME}>
                    <MuiForms 
                        config={{
                            variant: "standard"
                        }}
                        schema={props.schema} 
                        onSubmit={(data) => {
                            props.onSubmit && props.onSubmit(data);
                        }}
                    />
                </ThemeProvider>
            }
        </Popup>
    )
}


const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 16px;
`;

const FieldLabel = styled.label`
    font-weight: bold;
    width: 200px;
`;

const Footer = styled.div`
    text-align: center;
    margin-top: 32px;
    .cancel-btn {
        margin-right: 32px;
    }
`;

const FormHeader = styled.h2`
    position: absolute;
    top: 0;
    left: 36px;
`;

export default AddFormPopup;
