import styled from "@emotion/styled";
import { Button, MenuItem, Select, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import Gateway from "../../../../common/Gateway";
import Popup from "../../../Popup/Popup";
import "./styles.css";
import toast from "react-hot-toast";
import Util from "../../../../utils/Util";
import { FORM_THEME } from "../../../../common/theme";
import MuiForms from "mui-forms";
import supportSchema from "./schema.json";

const SupportRequestPopup = (props) => {
    const [schema, setSchema] = useState(null);

    useEffect(() => {
        const fields = supportSchema.fields.map(f => {
            if (f.name === "license_id") {
                f.meta.value = props.license_id;
            }
            return {...f};
        })
        setSchema({fields});
    }, []);
    return (
        <Popup onClose={props.onClose}>
            <FormHeader>Create Support Request</FormHeader>
            {
                schema && 
                <ThemeProvider theme={FORM_THEME}>
                    <MuiForms 
                        config={{
                            variant: "standard"
                        }}
                        schema={schema} 
                        onSubmit={(data) => {
                            Gateway.createSupportRequest(data.command, props.license_id).then(res => {
                                Util.toastSuccess("Successfully created support request");
                                props.onClose();
                            }).catch(err => {
                                toast.error("Failed to create support request")
                            });
                        }}
                    />
                </ThemeProvider>
            }
        </Popup>
    )
}

const Header = styled.h3`

`;

const Body = styled.div`
    text-align: center;

`;

const Footer = styled.div`
    text-align: center;
    margin-top: 16px;
`;

const Row = styled.div`
    display: flex;
    width: 800px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Para = styled.p`
    // margin-left: 16px;
`;

const Label = styled.h4`
    margin-right: 16px;
    text-align: right;
    width: 20%;
`;

const Col2 = styled.div`
    width: 500px;
    text-align: left;
`;

const FormHeader = styled.h2`
    position: absolute;
    top: 0;
    left: 36px;
`;

export default SupportRequestPopup;