import styled from "@emotion/styled";
import { Navigate, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { user } from "../../state/user";
import Register from "./Register";
import { useState } from "react";
import RegisteredSuccess from "./RegisteredSuccess";

const RegisterContainer = () => {
    const userDetails = useRecoilValue(user);
    // const nav = useNavigate();
    const [registered, setRegistered] = useState({
        msg: "",
        state: false
    });
    return (
        <Wrapper>
            {
                userDetails.isLoggedIn() 
                    ? <Navigate to="/" />
                    : registered.state ? <RegisteredSuccess msg={registered.msg} />
                    : <Register onRegisterSuccess={(msg) => {
                        // nav("/login");
                        setRegistered({
                            state: true,
                            msg
                        });
                    }} />
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
`;

export default RegisterContainer;