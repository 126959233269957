import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import pages from "../../config/nav.json";
import { Logo } from '../../common/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SidebarNav from './SidebarNav';

function NavBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const [isHome, setHome] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        if (location.pathname === "/support") {
            setHome(false);
        } else {
            setHome(true);
        }
    }, [location]);

  const handleSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  }

  const handleNavigate = (item) => {
    if (item.external) {
        window.open(item.href, "_blank");
    } else {
        navigate(item.href);
    }
}

  return (
    <AppBar position="static" color="transparent" 
        className="g-navbar"
        component="nav" sx={{
        boxShadow: "unset",
        margin: "16px 0"
    }}>
        <Toolbar disableGutters sx={{
            padding: {
                xs: "0 20px",
                sm: "0 20px",
                md: "0 60px",
                xl: "0 60px"
            },
        }}>
            <Logo onClick={() => {
                navigate("/");
            }} />
            <div className="flex-grow-1" />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page, idx) => (
                    <div key={idx}>
                        <NavItem
                            active={
                                page.href === "/" && isHome ? true 
                                : location.pathname === page.href ? true : false
                            }
                            onClick={() => handleNavigate(page)}
                        >
                            { page.label }
                        </NavItem>
                        {
                            idx < pages.length - 1
                            ? <NavItemSpacer>|</NavItemSpacer>
                            : <></>
                        }
                    </div>
                ))}
            </Box>
            <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ 
                        display: {
                            xs: "block", 
                            sm: "none", 
                            md: "none" 
                        }
                    }}
                    onClick={handleSidebarOpen}
                >
                  <MenuIcon sx={{
                    color: "#fff"
                  }} />
              </IconButton>

            {sidebarOpen && <SidebarNav handleNavigate={handleNavigate} onClose={() => {
              setSidebarOpen(false);
            }}/>}
        </Toolbar>
    </AppBar>
  );
}

const NavItem = styled.span`
    display: inline-block;
    font-weight: ${props => props.active ? "bolder": "normal"};
    color: #fff;
    text-decoration: ${props => props.active ? "underline" : "unset"};
    text-underline-offset: 8px;
    margin: 0 8px;
    cursor: pointer;
    line-height: 2;
`;

const NavItemSpacer = styled.span`
    color: #fff;
    font-size: 16px;
`;
export default NavBar;