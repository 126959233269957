import { useEffect } from "react";
import config from "./../config/config.json";

export const useScript = (src) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        document.body.append(script);
    }, [src]);
}

export const useZeromd = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdn.jsdelivr.net/gh/zerodevx/zero-md@2.4.0/src/index.min.js";
        script.type = "module";
        document.body.append(script);
    }, []);
}

export const useConfig = () => {
    return config;
}