import DataTableUtil from "./DatatableUtil";

class SchemaUtil {
    static toMetaformSchema(data) {
        const fields = data.col.filter(f => f.field !== "$")
            .sort((a,b) => {
                let val1 = a.editable ? 1 : 0;
                let val2 = b.editable ? 1 : 0;
                return val1 - val2;
            })
            .map(f => {
                let validation = f?.metadata?.validation;
                return {
                    "name": f.field,
                    "meta": {
                        "type": f?.metadata?.type,
                        "displayType": f?.metadata?.displayType || "label",
                        "displayName": f.headerName,
                        "value": DataTableUtil.getValue(f.metadata?.datatype, data.row[f.field]),
                        "options": f?.metadata?.options || [],
                        "isDisabled": f?.metadata?.isDisabled,
                        "displayProps": {
                            md: 12
                        },
                        validation
                    }
                }}
        );

        return {
            theme: {
                type: "mui",
                sectionLayout: "default",
                fieldLayout: "col",
                spacing: "",
                className: "",
                mui: {
                    variant: "outlined",
                    size: "small"
                }
            },
            fields
        }
    }

    static getAddAppSchema(schema) {
        const fields = schema.fields.map(f => {
            if (f.name === "application_id") {
                f.meta.value = crypto.randomUUID();
            }
            return {...f};
        });
        return {fields};
    }
}

export default SchemaUtil;
