import styled from "@emotion/styled";
import { useZeromd } from "../../common/hooks";

const Readme = () => {
    useZeromd();
    return (
        <Wrapper>
            <zero-md src="/README.md" style={{
                width: "calc(100% - 20px)",
                minWidth: "280px",
                margin: "0 16px"
            }}>
                <template>
                    <link
                        rel="stylesheet"
                        href="https://cdn.jsdelivr.net/gh/sindresorhus/github-markdown-css@4/github-markdown.min.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://cdn.jsdelivr.net/gh/PrismJS/prism@1/themes/prism.min.css"
                    />
                </template>
            </zero-md>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    // background-color: #ffffffaf;
    // border-radius: 10px;
`;

export default Readme;
