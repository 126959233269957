import styled from "@emotion/styled";
import DataTable from "../../../DataTable/DataTable";
import Searchbar from "../Searchbar/Searchbar";
import { useEffect,useState } from "react";
import ToggleMenu from "../../../ToggleMenu/ToggleMenu";

/**
 * title
 * rows
 * columns
 * handleAction
 * 
 * @param {*} props 
 * @returns 
 */
const DataTableComponent = (props) => {
    const [rows, setRows] = useState(props.rows || []);
    const handleSearch = (val) => {
        if (val) {
            const filteredVal = val.toLowerCase();
            const matchedRows = props.rows.filter(row => {
                const allKeys = Object.keys(row);
                const match = allKeys.filter(k => {
                    if (typeof row[k] === "string") {
                        if (row[k].toLowerCase().includes(filteredVal)) {
                            return true;
                        }
                    }
                    return false;
                });
                return match.length > 0;
            });
            setRows(matchedRows);
        } else {
            setRows(props.rows);
        }
    }

    useEffect(() => {
        if (props.rows && props.rows.length > 0) {
            setRows(props.rows);
        }
    }, [props.rows]);

    useEffect(() => {
        props.onLoaded && props.onLoaded();
    }, []);

    return (
        <Wrapper>
            <Header>
                <ToggleMenu />
                <div id="header-mount-hook"></div>
                <Searchbar onSearch={handleSearch} />
            </Header>
            <DataTable
                rows={rows}
                columns={props.columns}
                onAction={props.handleAction}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div``;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
`;

export default DataTableComponent;
