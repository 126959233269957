import styled from "@emotion/styled";
import { Button, ThemeProvider } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { EMAIL_PATTERN, ERROR_MSGS, SUCCESS_MSGS } from "../../common/constants";
import Gateway from "../../common/Gateway";
import { Form, Logo } from "../../common/styled";
import { GATEKEEPER_THEME } from "../../common/theme";
import Util from "../../utils/Util";
import { useScript } from "../../common/hooks";

function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    const [errors, setErrors] = useState({
        email: false,
        emailErrorMsg: "",
        password: false,
        passwordErrorMsg: ""
    });
    useScript("https://www.google.com/recaptcha/api.js?render=6LcvqxoqAAAAANbkeROsxN8k6EpybwMBNFVtrLUc");
    const handleValidation = () => {
        let emailValid = errors.email;
        let emailErrorMsg = "";
        let passwordErrorMsg = "";
        let passwordValid = errors.password;
        if (email && EMAIL_PATTERN.test(email)) {
            emailValid = true;
        } else {
            emailValid = false;
            emailErrorMsg = email ? "Please enter valid email address." : "Email is mandatory";
        }
        if (password) {
            passwordValid = true;
        } else {
            passwordValid = false;
            passwordErrorMsg = "Password is mandatory";
        }
        setErrors({
            email: !emailValid,
            password: !passwordValid,
            emailErrorMsg,
            passwordErrorMsg
        });
        return emailValid && passwordValid;
    }
    const handleLogin = (e) => {
        e.preventDefault();
        const isValid = handleValidation();
        if (!isValid) return;
        const params = {
            email,
            password
        };
        const toastId = toast.loading("Processing...");
        Util.validateWithCaptcha().then(res => {
            return Gateway.login(params, res);
        }).then(res => {
            const success = res.data.success;
            toast.remove(toastId);
            if (success) {
                if (res.data.api_key) {
                    Util.toastSuccess(SUCCESS_MSGS.LOGIN_SUCCESS);
                    props.onLoginSuccess(params.email, res.data);
                } else {
                    toast.error(ERROR_MSGS.ACCOUNT_DEACTIVATED);
                }
            } else {
                toast.error(ERROR_MSGS.LOGIN_FAILED);
            }
        }).catch(err => {
            toast.remove(toastId);
            toast.error(err?.response?.data?.message || ERROR_MSGS.SERVER_ERROR);
        })
    }
    return (
        <Wrapper>
            <ThemeProvider theme={GATEKEEPER_THEME}>
                <Form>
                    <h2>Login</h2>
                    <TextField label="Email" size="small" type="email"
                        error={errors.email}
                        helperText={errors.email ? errors.emailErrorMsg : ""}
                        value={email}
                        required
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }} />
                    <TextField label="Password" size="small" type="password"
                        error={errors.password}
                        helperText={errors.password ? errors.passwordErrorMsg : ""}
                        value={password}
                        required
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    />
                    <Button type="submit" variant="contained" onClick={handleLogin}>Login</Button>
                    <Button variant="text">
                        <Link to="/register">
                            New user? Register here
                        </Link>
                    </Button>
                </Form>
            </ThemeProvider>
            {/* <Toaster /> */}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 360px;
    margin: auto;
    @media (max-width: 500px) {
        min-width: 280px;
    }
`;

export default Login;
