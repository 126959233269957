import { toast } from "react-hot-toast";
import { useRecoilValue } from "recoil";
import { USER_ROLES } from "../../../../common/constants";
import Gateway from "../../../../common/Gateway";
import { user, userRole } from "../../../../state/user";
import columns from "./../../../../config/tables/applications.json";
import LoadedComponent from "../../../LoadedComponent";
import Util from "../../../../utils/Util";
import { createPortal } from "react-dom";
import AddFormPopup from "../../../AddFormPopup/AddFormPopup";
import { Button } from "@mui/material";
import { useState } from "react";
import addAppschema from "./add-app.json";
import SchemaUtil from "../../../../utils/SchemaUtil";
import TokenModal from "./TokenModal";

const MyApps = () => {
    const role = useRecoilValue(userRole);
    const userDetails = useRecoilValue(user);
    const [showAddForm, setShowAddForm] = useState(false);
    const [schema, setSchema] = useState(null);
    const [headerDisplayed, setHeaderDisplayed] = useState(false);
    const [showContent, setShowContent] = useState(true);
    const [tokenData, setTokenData] = useState({
        data: null,
        showModal: false
    });
    const loader = async (email, role) => {
        if (role === USER_ROLES.ADMIN) {
            const response = await Gateway.getApps();
            return response;
        } else {
            const response = await Gateway.getUserApps(email);
            return response;
        }
    }
    const getData = () => {
        try {
            const email = userDetails.getEmail();
            return loader(email, role);
        } catch (err) {
            toast.error("Failed to fetch applications");
        }
    }
    const reloadData = () => {
        setHeaderDisplayed(false);
        setShowContent(false);
        setTimeout(() => {
            setShowContent(true);
            setHeaderDisplayed(false);
        }, 300);
    }
    return (
        <>
            {
                showContent &&
                <LoadedComponent
                    loaded={!!role}
                    title="Applications"
                    type="application"
                    dataKey="applications"
                    columns={columns}
                    handleEdit={(data) => {
                        const editableData = Util.getEditableData(data, columns);
                        return Gateway.updateApp(editableData);
                    }}
                    handleAction={(actionType, data) => {
                        setTokenData({
                            data,
                            showModal: true
                        });
                    }}
                    getData={getData}
                    handleLoaded={() => {
                        setHeaderDisplayed(true);
                    }}
                />
            }
            {headerDisplayed && createPortal(<Button className="action-btn" onClick={() => {
                const schema = SchemaUtil.getAddAppSchema(addAppschema);
                setSchema(schema);
                setShowAddForm(true);
            }} variant="contained">Register Application</Button>, Util.getHeaderPortal())}
            {
                showAddForm && createPortal(<AddFormPopup
                    title="Register Application"
                    schema={schema}
                    onSubmit={(data) => {
                        Gateway.addApp(data).then(res => {
                            toast.success("Application successfully registered");
                            setShowAddForm(false);
                            setSchema(false);
                            reloadData();
                        }).catch(err => {
                            const errorMsg = err?.response?.data?.message;
                            toast.error(errorMsg ?? "Failed to register application");
                        });
                    }}
                    onClose={() => {
                        setShowAddForm(false);
                        setSchema(false);
                    }} />, Util.getModalContainer())
            }
            {
                tokenData.showModal && createPortal(<TokenModal
                    title="Confirmation"
                    name={tokenData.data?.application_name ?? "application"}
                    onSubmit={() => {
                        const payload = {
                            application_id: tokenData?.data?.application_id
                        }
                        Gateway.createAppToken(payload).then(res => {
                            if (res.status === 201) {
                                toast.success("Successfully created app token");
                                reloadData();
                            } else {
                                toast.error("Failed to create app token");
                            }
                        }).catch(err => {
                            const errorMsg = err?.response?.data?.message;
                            toast.error(errorMsg ?? "Failed to create app token");
                        });
                        setTokenData({
                            data: null,
                            showModal: false
                        });
                    }}
                    onClose={() => {
                        setTokenData({
                            data: null,
                            showModal: false
                        });
                    }} />, Util.getModalContainer())
            }
        </>
    )
}

export default MyApps;
