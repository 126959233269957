import Login from "../Login";
import withHomeHOC from "./withHomeHOC";

const HomeWithLogin = () => {
    return <HomeWithLoginHOC />
}

const HomeWithLoginHOC = withHomeHOC(Login);

export default HomeWithLogin;
