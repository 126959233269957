import styled from "@emotion/styled";
import { toast } from "react-hot-toast";
import { useRecoilValue } from "recoil";
import { USER_ROLES } from "../../../../common/constants";
import Gateway from "../../../../common/Gateway";
import { user, userRole } from "../../../../state/user";
import * as columns from "./../../../../config/tables/support-response.json";
import LoadedComponent from "../../../LoadedComponent";
import Util from "../../../../utils/Util";

const SupportResponse = () => {
    const userDetails = useRecoilValue(user);
    const role = useRecoilValue(userRole);
    const loader = async (email, role) => {
        if (role === USER_ROLES.ADMIN) {
          const response = await Gateway.getSupportResponse();
          return response;
        } else {
          const response = await Gateway.getUserSupportResponse(email);
          return response;
        }
    }
    const getData = () => {
        const email = userDetails.getEmail();
        try {
            return loader(email, role);
        } catch(err) {
            toast("Failed to fetch support responses");
        }
    }
    return <LoadedComponent
        loaded={!!role}
        title="Support Responses"
        type="support_responses"
        dataKey="support_responses"
        columns={columns.default}
        handleAction={(type, row) => {
            Gateway.getFile(row.response_id).then(res => {
                const fileContent = res.data.file_content;
                Util.downloadFile(fileContent, row.file);
            }).catch(res => {
                toast.error("Error downloading file. Please try again later.")
            });
        }}
        handleEdit={(data) => {
            return Gateway.updateSupportRequest(data);
        }}
        getData={getData}
    />;
}

export default SupportResponse;
