import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { CONSTANTS } from "../common/constants";
import { user } from "../state/user";

const PrivateRoute = (props) => {
    const userDetails = useRecoilValue(user);

    if (userDetails.isLoggedIn()) {
        return props.children;
    }
    return <Navigate to="login" />
}

export default PrivateRoute;
