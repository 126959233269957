import styled from "@emotion/styled";
import { Logo, Wrapper } from "../../common/styled";
import Readme from "./Readme";
import { Component } from "react";
import Navbar from "../Navbar";
import Copyright from "../Copyright";
import { ThemeProvider } from "@mui/material";
import { GATEKEEPER_THEME } from "../../common/theme";

const withHomeHOC = (FormComponent) => {
    return class extends Component {
        render() {
            return (
                <ThemeProvider theme={GATEKEEPER_THEME}>
                    <Wrapper className="g-home-container">
                        <Header className="g-header">
                            {/* <Logo className="g-logo" /> */}
                            <Navbar />
                        </Header>
                        <Body className="g-body">
                            <ReadmeWrapper className="g-reader-wrapper">
                                <Readme />
                            </ReadmeWrapper>
                            <RightWrapper className="g-form-wrapper">
                                <FormComponent />
                            </RightWrapper>
                        </Body>
                        <Copyright className="g-footer" />
                    </Wrapper>
                </ThemeProvider>
            )
        }
    }
}


const ReadmeWrapper = styled.div`
    width: 100%;
    max-width: 60%;
    background-color: #ffffffaf;
    border-radius: 10px;
    height: calc(100vh - 300px);
    min-height: 550px;
    overflow-y: auto;
    padding: 24px 32px;
    @media (min-width:996px) and (max-width:1200px) {
        max-width: 50%;
        padding: 24px 16px;
    }
    @media (max-width: 500px) {
        min-width: calc(320px - 16px);
        padding: 24px 4px;
    }
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Body = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
`;

const RightWrapper = styled.div`
    
`;

export default withHomeHOC;