import styled from "@emotion/styled";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { IconButton } from "@mui/material";

const RegisteredSuccess = (props) => {
    return (
        <Wrapper>
            <div>
                <p className="text-center">
                <IconButton color="success" aria-label="add an alarm" sx={{
                    fontSize: "32px"
                }}>
                    <TaskAltIcon />
                </IconButton>
                </p>
                <p>
                    { props.msg }
                </p>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 32px;
    border-radius: 10px;
    background: #ffffffaf;
    text-align: center;
    @media (max-width: 500px) {
        min-width: 280px;
        padding: 24px 16px;
    }
    @media (min-width: 995px) {
        max-width: calc(360px - 64px);
    }
`;

export default RegisteredSuccess;
