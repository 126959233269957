import { atom, selector } from "recoil";
import { CONSTANTS } from "../common/constants";
import Cookies from "js-cookie";
export const user = atom({
    key: "user",
    default: {
        isLoggedIn: () => {
            const userApiKey = Cookies.get(CONSTANTS.USER_API_KEY);
            const userEmail = Cookies.get(CONSTANTS.USER_EMAIL);
            return userApiKey && userEmail;
        },
        getEmail: () => {
            return Cookies.get(CONSTANTS.USER_EMAIL);
        },
        setUserDetails: (email, apiKey) => {
            Cookies.set(CONSTANTS.USER_EMAIL, email, {
                expires: 1
            });
            Cookies.set(CONSTANTS.USER_API_KEY, apiKey, {
                expires: 1
            });
        },
        clearLogin: () => {
            Cookies.remove(CONSTANTS.USER_EMAIL);
            Cookies.remove(CONSTANTS.USER_API_KEY);
        },
        info: {}
    }
});

export const userRole = selector({
    key: "user-role",
    get: ({get}) => {
        const userInfo = get(user);
        return userInfo.info?.role;
    }
});