import styled from "@emotion/styled";

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background: linear-gradient(315deg,rgba(101,0,94,1) 3%,rgba(60,132,206,1) 38%,rgba(48,238,226,1) 68%,rgba(255,25,25,1) 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 360px;
    margin: auto;
    @media (max-width: 500px) {
        min-width: 280px;
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 10px;
    background: #ffffffaf;
    > * {
        margin-top: 16px!important;
        margin-bottom: 16px!important;
    }
`;

const LogoContainer = styled.div`
    cursor: pointer;
    img {
        width: auto;
        max-width: calc(250px - 32px);
    }
`;

export const Logo = (props) => <LogoContainer className={"text-center " + props.className} onClick={props.onClick}>
    <img src="/gatekeeper_logo.png" alt="" />
</LogoContainer>;