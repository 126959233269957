import ContactUs from "../ContactUs";
import withHomeHOC from "./withHomeHOC";

const HomeWithSupport = () => {
    return <HomeWithSupportHOC />
}

const HomeWithSupportHOC = withHomeHOC(ContactUs);


export default HomeWithSupport;
