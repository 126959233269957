import { createTheme } from "@mui/material";
import * as tableConfig from "../config/tables/table.json";
import formConfig from "../config/form/form.json";

const ColorsStyles = {
    ".MuiOutlinedInput-root": {
        "&:hover": {
            ".MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--primary-color)"
            }
        },
        "&.Mui-error": {
            "&:hover": {
                ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#d32f2f"
                }
            }
        },
        "&.Mui-focused": {
            ".MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--primary-color)"
            }
        }
    },
    ".MuiFormLabel-root": {
        "&.Mui-focused": {
            color: "var(--primary-color)"
        },
        "&.Mui-error": {
            color: "#d32f2f"
        }
    }
};

export const GATEKEEPER_THEME = createTheme({
    palette: {
        primary: {
            main: "#673ab7"
        }
    },
    typography: {
        fontFamily: "Poppins-Regular"
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: ColorsStyles
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: ColorsStyles
            }
        }
    }
});

export const TABLE_THEME = createTheme({
    typography: {
        fontSize: tableConfig.default?.font?.size || 12,
        fontFamily: "Poppins-Regular"
    }
});

export const FORM_THEME = createTheme({
    palette: {
        primary: {
            main: "rgb(46,51,54)"
        }
    },
    typography: {
        fontFamily: "Poppins-Regular",
        fontSize: formConfig.font?.size || 12
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: ColorsStyles
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: ColorsStyles
            }
        }
    }
});

export const SEARCHBAR_THEME = createTheme({
    palette: {
        primary: {
            main: "rgb(46,51,54)"
        }
    },
    typography: {
        fontFamily: "Poppins-Regular",
        fontSize: formConfig.font?.size || 12,
        fontStyle: "italic"
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    ".MuiInputBase-root": {
                        borderRadius: "24px"
                    }
                }
            }
        }
    }
});