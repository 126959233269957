import styled from "@emotion/styled";
import { Button, TextField, ThemeProvider } from "@mui/material";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { EMAIL_PATTERN, NAME_PATTERN, SUCCESS_MSGS } from "../../common/constants";
import Gateway from "../../common/Gateway";
import { Form } from "../../common/styled";
import { GATEKEEPER_THEME } from "../../common/theme";
import { useScript } from "../../common/hooks";
import Util from "../../utils/Util";

const Register = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({
        email: false,
        emailErrorMsg: "",
        password: false,
        passwordErrorMsg: "",
        name: false,
        nameErrorMsg: ""
    });

    useScript("https://www.google.com/recaptcha/api.js?render=6LcvqxoqAAAAANbkeROsxN8k6EpybwMBNFVtrLUc");

    const handleRegister = (e) => {
        e.preventDefault();
        const isValid = handleValidation();
        if (!isValid) return;
        const params = {
            email,
            password,
            name
        };
        const promise = Util.validateWithCaptcha().then(res => {
            return Gateway.register(params, res);
        }).then(res => {
            props.onRegisterSuccess(SUCCESS_MSGS.REGISTERED_MSG);
        });
        toast.promise(promise, {
            loading: "Processing...",
            success: "Registered",
            error: (err) => err.response?.data?.message
        })
    }
    const handleValidation = () => {
        let emailValid = errors.email;
        let emailErrorMsg = "";
        let passwordErrorMsg = "";
        let passwordValid = errors.password;
        let nameValid = errors.name;
        let nameErrorMsg = "";
        if (name && NAME_PATTERN.test(name)) {
            nameValid = true;
        } else {
            nameValid = false;
            nameErrorMsg = email ? "Please enter a valid name." : "Name is mandatory";
        }
        if (email && EMAIL_PATTERN.test(email)) {
            emailValid = true;
        } else {
            emailValid = false;
            emailErrorMsg = email ? "Please enter valid email address." : "Email is mandatory";
        }
        if (password) {
            passwordValid = true;
        } else {
            passwordValid = false;
            passwordErrorMsg = "Password is mandatory";
        }
        setErrors({
            email: !emailValid,
            password: !passwordValid,
            name: !nameValid,
            emailErrorMsg,
            passwordErrorMsg,
            nameErrorMsg
        });
        return emailValid && passwordValid && nameValid;
    }
    return (
        <Wrapper>
            <ThemeProvider theme={GATEKEEPER_THEME}>
                <Form>
                    <h2>Register</h2>
                    <TextField label="Name" size="small" type="name"
                        error={errors.name}
                        helperText={errors.name ? errors.nameErrorMsg : ""}
                        value={name}
                        required
                        onChange={(e) => {
                            setName(e.target.value);
                        }} />
                    <TextField label="Email" size="small" type="email"
                        error={errors.email}
                        helperText={errors.email ? errors.emailErrorMsg : ""}
                        value={email}
                        required
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }} />
                    <TextField label="Password" size="small" type="password"
                        error={errors.password}
                        helperText={errors.password ? errors.passwordErrorMsg : ""}
                        value={password}
                        required
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    />
                    <Button type="submit" variant="contained" onClick={handleRegister}>Register</Button>
                    <Button variant="text">
                        <Link to="/login">
                            Already resgisterd ? Login here
                        </Link>
                    </Button>
                </Form>
            </ThemeProvider>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 360px;
    @media (max-width: 500px) {
        min-width: 280px;
    }
`;

export default Register;
