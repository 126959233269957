import { toast } from "react-hot-toast";
import { useRecoilValue } from "recoil";
import { USER_ROLES } from "../../../../common/constants";
import Gateway from "../../../../common/Gateway";
import { user, userRole } from "../../../../state/user";
import columns from "./../../../../config/tables/support-request.json";
import LoadedComponent from "../../../LoadedComponent";
import Util from "../../../../utils/Util";

const SupportRequests = () => {
    const userDetails = useRecoilValue(user);
    const role = useRecoilValue(userRole);
    const loader = async (email, role) => {
        if (role === USER_ROLES.ADMIN) {
          const response = await Gateway.getSupportRequest();
          return response;
        } else {
          const response = await Gateway.getUserSupportRequest(email);
          return response;
        }
    }
    const getData = () => {
        const email = userDetails.getEmail();
        try {
            return loader(email, role);
        } catch(err) {
            toast("Failed to fetch support requests");
        }
    }
    return <LoadedComponent
        loaded={!!role}
        title="Support Requests"
        type="support_requests"
        dataKey="support_requests"
        columns={columns}
        handleEdit={(data) => {
            const editableData = Util.getEditableData(data, columns);
            return Gateway.updateSupportRequest(editableData);
        }}
        getData={getData}
    />;
}

export default SupportRequests;
