import { toast, Toaster, ToastBar } from "react-hot-toast";
import TOAST_CONFIG from "./../config/toast.json";
import { Button, IconButton } from "@mui/material";
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const CustomToastBar = () => <Toaster toastOptions={TOAST_CONFIG} position="top-right">
  {(t) => (
    <ToastBar 
        toast={t}>
        {({ icon, message }) => (
            <>
                <ToastIcon type={t.type} />
                {message}
                {t.type !== "loading" && (
                    <Button variant="text" title="Close"
                        sx={{
                            color: "white",
                            fontSize: "20px"
                        }}
                        onClick={() => toast.dismiss(t.id)}>
                        <i className="bi bi-x-circle"></i>
                    </Button>
                )}
            </>
        )}
    </ToastBar>
  )}
</Toaster>;

const ToastIcon = (props) => {
    if (props.type === "loading") {
        return (
           <IconButton aria-label="add an alarm">
               <HourglassEmptyIcon />
           </IconButton>
       );
    }
    if (props.type === "error") {
        return (
           <IconButton color="error" aria-label="add an alarm">
               <ErrorIcon />
           </IconButton>
       );
    }
    if (props.type === "success") {
        return (
           <IconButton color="success" aria-label="add an alarm">
               <TaskAltIcon />
           </IconButton>
       );
    }
}

export default CustomToastBar;
